import React, { useState, useEffect } from 'react';
import './select-method-colombia.scss';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CardContainer from '../../../components/CardContainer';
import medios_colombia from '../../../assets/images/pagos/medios_epayco.png';

//import { useHistory } from 'react-router-dom';
import httpPanama from '../../../https/apiPayPanama';
import Swal from 'sweetalert2';
import Spinner from 'react-bootstrap/Spinner';
import { listMethods } from './methods';


import { Dialog, Button } from '@material-ui/core';

const epaycovar=  window.ePayco.checkout.configure({
    key: '78c8edb1a3ca549ea8f164fcf2df2ddb',
    test: false
});

//const qs = require('querystring')
const SelectMethodColombia = (props) => {
    let ppp = 'ppp';
    if (props.match.path.indexOf('auth') > -1) {
        ppp = '';
    }
    const [loading, setLoading] = useState(false);
    const [loadingerror, setLoadingerror] = useState(false);
    const [checked, setChecked] = useState('pminimo');
    const [minvalue] = useState(props.pagosInfo.total);

    const [formControls, setFormControls] = useState({
        c_emp_: 'JA',
        cardNumber: "",
        expirationDate_y: "2020",
        expirationDate_m: "01",
        expirationDate: "",
        cardCode: "",
        amount: props.pagosInfo.total,
        cuenta: props.pagosInfo.factura,
        n_ide: "",
        email: "",
        firstName: "",
        lastName: "",
        n_ide_tarjeta: "",
        api_key: "d47c29cfdf8e2456ac678c51f9e4ddfa8ec577f64e98aa9e863399f6a10210d4",
        pais: "CO",
        customerIP: "181.135.166.136",
        idMessageIn: false,
        MessageIn: '',
        typeMessage: '',
        loadingPay: false,
        orderId:0
    });


    //    let history = useHistory();



    useEffect(() => {
        if (Object.keys(props.pagosInfo).length === 0) {
            if (props.match.path.indexOf('auth') > -1) {
                props.history.push(`/auth/pay`);
            } else {
                props.history.push(`/pagos/colombia`);
            }
        }
    });


    /*     const goToPay = () => {
            history.push({
                pathname: '/pagos/pay-panama'
            })
        }; */


    let finishSubmit = () => {
        let num = formControls.amount;
        if(Number(num)<100){
            Swal.fire({
                title: 'Valor a pagar invalido',
                text: 'El valor minimo a pagar es de 10.000 pesos.',
                icon: 'error',
                showCancelButton: false
            })
            return false;
        }


        const pagosData = {
            "amount": parseInt(num),
            "cuenta": props.pagosInfo.cuenta,
            "cedula": props.pagosInfo.n_ide,
            "estado":1
        };


        setFormControls(
            {
                ...formControls,
                loadingPay: true
            })
        setLoading(true);
        httpPanama.post(
            (window.DOMINIO_JAMAR_COLOMBIA.includes(window.location.host))
//            ? 'https://5negiw991g.execute-api.us-east-1.amazonaws.com/dev/create/transaction/' //dev,                   
//            : 'https://io96hdw1m5.execute-api.us-east-1.amazonaws.com/prd/create/transaction/' //Prod
            ? 'https://io96hdw1m5.execute-api.us-east-1.amazonaws.com/prd/create/transaction/'                   
            : 'https://io96hdw1m5.execute-api.us-east-1.amazonaws.com/prd/create/transaction/' 
            ,
            pagosData).then(result => {
                let params = { show: true, message: '', typeMessage: 'info' }
                setLoading(false);

                if (result.status === 200) {
                    if (result.data) {
                            setFormControls(
                                {
                                    ...formControls,
                                    orderId: result.data.id_order
                                })
                                handleSubmit2(result.data.id_order);
                            
                    }
                } else {
                    params.message = 'La transacción no tuvo éxito.';
                    setFormControls(
                        {
                            ...formControls,
                            idMessageIn: true,
                            MessageIn: 'Lo sentimos, no se pudo completar la transacción. Intente mas tarde.',
                            typeMessage: 'error',
                            loadingPay: false

                        })
                    Swal.fire({
                        title: 'Error de conexion!',
                        text: 'Lo sentimos, no se pudo completar la transacción. Intente mas tarde.',
                        icon: 'error',
                        showCancelButton: false
                    })


                }
            }, () => {
                setLoading(false);
                setFormControls(
                    {
                        ...formControls,
                        idMessageIn: true,
                        MessageIn: 'Lo sentimos, no se pudo completar la transacción. Intente mas tarde.',
                        typeMessage: 'error',
                        loadingPay: false

                    })
                Swal.fire({
                    title: 'Error de conexion!',
                    text: 'Lo sentimos, no se pudo completar la transacción. Intente mas tarde.',
                    icon: 'error',
                    showCancelButton: false
                })


            });


    }

    let handleSubmit2  =(orderId)=>{ 
        let num = formControls.amount;
        let urlBase = process.env.REACT_APP_BASE_RECAUDO;

    var data={
        //Parametros compra (obligatorio)
        name: "Pago cuota jamar",
        description: "Pago cedula " + props.pagosInfo.n_ide,
        invoice: orderId,
        currency: "cop",
        amount: Number(num),
        tax_base: "0",
        tax: "0",
        country: "co",
        lang: "es",
        //Onpage="false" - Standard="true"
        external: "false",
        confirmation: (window.DOMINIO_JAMAR_COLOMBIA.includes(window.location.host))? 
        `${urlBase}execute` //dev       
        : `${urlBase}execute`, //Prod        
        //response: window.location.origin+'/pagos/confirmacionpayu?orderId='+orderId,
        response: 'https://2zwuuknm48.execute-api.us-east-1.amazonaws.com/default/lambda-prueba-epayco-redirect2?orderId='+orderId,
        //Atributos cliente 'https://5negiw991g.execute-api.us-east-1.amazonaws.com/dev/transaction/execute' //dev
        name_billing:  formControls.firstName + " "+formControls.lastName,
        type_doc_billing: "cc",
        number_doc_billing:  props.pagosInfo.n_ide

       //atributo deshabilitación metodo de pago
//        methodsDisable: ["TDC", "PSE","SP","CASH","DP"]
        }
    epaycovar.open(data);
}

    handleSubmit2 = handleSubmit2.bind(this);

    const changeHandler = (event) => {
        props.pagosInfo.total = event.target.value;
        setFormControls(
            {
                ...formControls,
                amount: event.target.value
            })
    }

    const handleChange = (value, cantidad) => {
        setChecked(value);
        setFormControls(
            {
                ...formControls,
                amount: cantidad >= 0 ? cantidad : formControls.amount
            })
    };

    const hambrePayment = (data) => {
        window.location.href = data;
    };

    return (
        <div >



            <div className={"centrar " + ppp} >
                <div className={"card-content max-960 "}>
                    <CardContainer style={{ marginTop: '0px !important' }} cardTitle="Datos del pago">
                        <PerfectScrollbar>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8 col-sm-7 col-8">
                                        <label className="card-title-desc">Nombre de usuario</label>
                                        <div className="card-title-subdesc" >Sr(a) {props.pagosInfo.first_name ? props.pagosInfo.first_name : props.pagosInfo.nombre} {props.pagosInfo.last_name} </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-5 col-4">
                                        <label className="card-title-desc">No. de cuenta:  </label>
                                        <div className="card-title-subdesc" ><h6>{props.pagosInfo.cuenta} </h6> </div>


                                    </div>
                                </div>

                            </div>
                        </PerfectScrollbar>
                    </CardContainer >
                </div >

            </div>

            <div className={"centrar " + ppp} >
                <div className={"card-content max-960 mt-4"}>
                    <CardContainer className="mb15" cardTitle="Seleccione metodo de pago">
                        <PerfectScrollbar>

                            <div className="col-12">

                                <div className="row">
                                    
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                        <label className="otrovalor card-title-desc" >
                                            <input
                                                type="checkbox"
                                                checked={checked === 'pminimo'}
                                                onChange={(e) => handleChange(e.target.id, minvalue)}
                                                id="pminimo"
                                                className="inputotrovalor"
                                            />
                                            <div className="pagavalor card-title-subdesc">
                                                Pago minimo: <span className="valorpagar"> $ {minvalue}</span>
                                            </div>
                                        </label>

                                        <label className="otrovalor card-title-desc" >
                                            <input
                                                type="checkbox"
                                                checked={checked === 'ptotal'}
                                                onChange={(e) => handleChange(e.target.id, props.pagosInfo.saldo)}
                                                id="ptotal"
                                                className="inputotrovalor"
                                            />
                                            <div className="pagavalor card-title-subdesc">
                                                Pago total: <span className="valorpagar"> $ {props.pagosInfo.saldo}</span>
                                            </div>
                                        </label>

                                        <label className="otrovalor card-title-desc" >
                                            <input
                                                type="checkbox"
                                                checked={checked === 'potros'}
                                                onChange={(e) => handleChange(e.target.id, -1)}
                                                className="inputotrovalor"
                                                id="potros"
                                            />
                                            <div className="pagavalor card-title-subdesc">
                                                Otro monto
                                            </div>
                                        </label>
                                    </div>
                                </div>

                                {checked === false ? '' :
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="card-title-subdesc" >
                                                Monto a pagar: <input type="text" className="form-control input-nobor inputvalue " id="IDENT"
                                                    aria-describedby="Cantidad" placeholder="100.05" value={formControls.amount} onChange={changeHandler} />
                                            </div>
                                        </div>
                                    </div>
                                }
                                <br />
                                <hr width="100%" color="#EDECEC" size="7" />


                                <div className="row">

                                    {/*                                     <div className="col-12">
                                        {formControls.idMessageIn ? <div className="form-group"><Alert severity={formControls.typeMessage}>{formControls.MessageIn}</Alert></div> : null}
                                    </div>
 */}
                                    <div className="col-12">
                                        <div className="row justify-content-between">

                                            {formControls.loadingPay === false ?
                                                <div className="col-sm-12 col-lg-5 col-md-6 inputotrovalor" onClick={finishSubmit} >
                                                    <div className="center text-center  boton-select">
                                                        <div className="mpimgcontainer2">
                                                            <img className="logo_epayco" src={medios_colombia} alt="Credit card" />
                                                        </div>
                                                    </div>

                                                </div>

                                                : <div className="col-sm-8 col-xs-10 col-lg-4 col-md-6" >
                                                    <div className="col-12 center text-center">
                                                    </div>
                                                </div>}
                                            {listMethods.map(i => {
                                                return (


                                                    <div className="col-sm-12 col-lg-5 col-md-6 inputotrovalor" onClick={async () => {
                                                        if(formControls.amount<1){
                                                            Swal.fire({
                                                                title: 'Valor a pagar invalido',
                                                                text: 'El valor minimo a pagar es de 10 dolares.',
                                                                icon: 'error',
                                                                showCancelButton: false
                                                            })
                                                            return false;
                                                        }
                                                
                                                        try {
                                                            setLoading(true);
                                                            const payMents = await i.action(props.pagosInfo.total, i.redirect, props.pagosInfo.n_ide)
                                                            let url='';
                                                            if(i.title === 'Yappy'){
                                                                url =payMents.data.url;
                                                            }else{
                                                                url = payMents.data.data.url;
                                                            }
                                                            await hambrePayment(url);
                                                            setLoading(false);

                                                        } catch (error) {
                                                            setLoading(false);
                                                            Swal.fire({
                                                                title: 'Error de conexion!',
                                                                text: 'Ha ocurrido un error con el banco, intentelo nuevamente',
                                                                icon: 'error',
                                                                showCancelButton: false
                                                            })

                                                        }

                                                    }}>
                                                        <div className="center text-center  boton-select">
                                                            <div className="mpimgcontainer">
                                                                <img className="mpimg" src={i.img} alt="Credit card" />
                                                            </div>
                                                            <div className="mptext">
                                                                {i.title}
                                                            </div>

                                                        </div>
                                                    </div>

                                                )

                                            })}

                                            {/*        <div className="col-sm-8 col-xs-10 col-lg-6 col-md-8 boton-select" onClick={() => {
                                                props.history.push(`/pagos/pay-panama`);
                                            }} >
                                                <div className="col-12 center text-center">
                                                    <h5>NEQUI</h5>
                                                </div>
                                            </div> */}





                                        </div>
                                    </div>


                                </div>
                            </div>
                        </PerfectScrollbar>
                    </CardContainer >
                </div >
            </div>



{/*             <div className={"centrar " + ppp} >
                <div className={"card-content max-960 mt-4"}>
                    <CardContainer style={{ marginTop: '0px !important' }}>
                        <PerfectScrollbar>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-12 content-inputs">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <label className="card-title-desc">Aceptamos los siguientes metodos de pago:</label>
                                            </div>
                                        </div>
                                        <div className="row imgsmps">
                                            <img className="mpimgs" src={clave} alt="tarjeta clave" />
                                            <img className="mpimgs" src={visa} alt="visa" />
                                            <img className="mpimgs" src={mastercard} alt="mastercard" />
                                            <img className="mpimgs" src={yapi_general} alt="yappi" />
                                            
                                        </div>


                                    </div>


                                </div>
                            </div>
                        </PerfectScrollbar>
                    </CardContainer >
                </div >

            </div>
 */}

            <Dialog open={loading}>
                <div className="text-center p30">
                    <div className="text-center">
                        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                    </div>
                    <div>
                        Validando...
                    </div>
                </div>

            </Dialog>

            <Dialog open={loadingerror}>
                <div className="text-center p30">
                    <div className="text-center">
                        <p>Ha ocurrido un error, vuelva a intentarlo mas tarde</p>
                    </div>
                    <div className="text-center mt2">
                        <Button variant="contained" onClick={() => setLoadingerror(false)} >OK</Button>
                    </div>
                </div>

            </Dialog>

        </div>



    )

}

const mapStateToProps = state => state;

export default connect(mapStateToProps, {})(SelectMethodColombia);