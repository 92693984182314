import React, {useEffect, useRef, useState} from "react";
import "./styles.scss";
import CardContainer from "../CardContainer";
import pagaygana from "../../assets/images/pagaygana/banner.png";
import http from '../../https/api';

//mini componentes
// import RenderSantasIcon from "./RenderIcons";
// import CodeComponent this replace with popup notification from "./CodeComponent";
import PromoComponent from "./PromoComponent";
import { encryptData, getCookie } from "../../util/util";

// Componente de paga y gana en el portal del cliente
export const SantiPuntos =({cedula, estilos, cuenta, pais}) => {
  //elementos interactivos
  const buttonElement = useRef();
  const containerSantas = useRef(0);
  const containerActions = useRef(0);
  //state declarations 
  const [santipuntosState, setSantipuntosState] = useState(0);

  const [buttonText, setButtonText] = useState('PON A PRUEBA TU MEMORIA');
  const [isHidden, setIsHidden] = useState(false);
  const [codePromo, setCodePromo] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [showComponentCol, setShowComponent] = useState({
    "showPan":false,
    "showCol":false
  });
  const [promoId, setPromoId] = useState(pais == "JA" ? "978569" : "978332");
  const [loadCampaing, setLoadCampaing] = useState(false);

  //Funcion para validar sí el cliente tiene el saldo al día o tiene saldo vencido
  useEffect(() => {

async function getComponetState() {
  await fetch("https://cdn.shopify.com/s/files/1/0516/5478/7271/files/portalComponent.json?"+Math.random())
  .then(response => response.json())
  .then(data =>{
    setShowComponent({
      showCol: data.CO.showComponent,
      showPan: data.PA.showComponent
    });
    })
}
    
async function getStatusSantiPunto(documento) {
    let url = process.env.REACT_APP_PUNTOS;
    let token = getCookie('token');
    let formData = {
      cedula: encryptData(documento),
      company: pais
    }
    try {
        let response = await fetch(`${url}puntos_cliente`, { method: 'POST', body: JSON.stringify(formData), headers: { 'Authorization': `Bearer ${token}` } }); //6

        if (response.status !== 200) {
            throw new Error('Request failed with status ' + response.status);
        }

        let data = await response.json();  
        setSantipuntosState(data.puntos);
        setLoadCampaing(true)
        setButtonText("PON A PRUEBA TU MEMORIA");
        if (pais === "JA" ) {
          if (data.puntos >= 14 && data.aplica == "S" ) {
            getCode(documento, "JA");
            setIsActive(true);
            setButtonText('PON A PRUEBA TU MEMORIA');
          } else {
              buttonElement.setAtt = true;
          }
        } else if (pais === "JP" ) {
          if (data.aplica == "S" ) {
            getCode(documento, "JP");
            setIsActive(true);
          } else {
              buttonElement.setAtt = true;
          }
        }
          
    } catch (error) {
        console.log(error)
    }
}

async function getCode(documento, pais) {
    let conmpanyIdForPagaYGana = pais;
    let codigoCampana = promoId;
    const url = `https://x820g2be4k.execute-api.us-east-1.amazonaws.com/prd/v1/easypromo/validate-access-code/${conmpanyIdForPagaYGana}/${documento}/${codigoCampana}?validate_points=true`;

    const headers = { 'Content-Type': 'application/json' };
    try {
        let response = await fetch(url, { method: 'GET', headers: headers });

        if (response.status !== 200) {
            throw new Error('Request failed with status ' + response.status);
        }

        let data = await response.json();

        if (data.statusCode === 200) {
            let code = data.body.data.access_code;
            setCodePromo(code);
        }
    } catch (error) {
        console.error('Error:', error);
    }
} 
getComponetState();
getStatusSantiPunto(cedula);
}, [santipuntosState]);

  return (
    showComponentCol.showCol && pais === "JA" ?
    <>
    {isHidden && <PromoComponent code={codePromo} stateIsActive={setIsHidden} campaing={promoId} />}
    <div className={`contenedor-tarjeta`}>
      <div className="container d-flex justify-content-center">
        <div className="row flex-column santipunto-container p-0">

          <img src="https://cdn.shopify.com/s/files/1/0516/5478/7271/files/top-img-pagagana.png?v=1718142135" alt="" />

                {/* <div className="container-bg-navidad d-flex flex-column justify-content-center align-items-center mb-5" style={{ backgroundColor: `#D12C3C` }}>

                    <p className="santipunto-parrafo" style={{color: '#fff'}}>Por cada <span><b>$30.000</b> <br /> <b>pagados</b></span> recibirás un <br /> <b>BOBLE PUNTAJE</b>.</p>

                    <div ref={containerSantas} className="container-santas d-flex justify-content-center">
                      <RenderSantasIcon santipuntos={santipuntosState} />
                    </div>
                    <div ref={containerActions} className={`container-actions ${isHidden ? 'd-none' : ''}`}>
                    </div>
                </div> */}
                <div className="container-information d-flex flex-column align-items-center">
                    <p className="mt-3">
                      Para participar debes acumular
                      14 puntos y estar al día con tus pagos.<br />
                      <span className="main-text d-block mt-2">Tus puntos acumulados son:</span> <br />
                      <span className="puntos-acumulados">
                        <b>{ santipuntosState > 0 ? santipuntosState : 0 }</b>
                      </span>
                      <br />
                      
                        { !isActive ? <span className="estas-aldia opaco"> <b> Debes estar al día</b></span> : 
                        <span className="estas-aldia"> <b> Estas al día</b> </span> }
                        
                      
                    </p>

                    <button 
                      ref={buttonElement} 
                      className={`fetch-santipuntos pb-2 pt-2 ${isActive ? 'success' : ''}`}
                      onClick={() => {
                        if (!isActive) return;
                        setIsHidden(true);
                        setButtonText('Juego comenzado');
                      }}
                    >
                      { buttonText || 'Comienza a jugar' }
                      <img className="h-100" src="https://cdn.shopify.com/s/files/1/0516/5478/7271/files/icon-clic.svg?v=1718142233" alt="" />
                    </button>
                 

                    {/* <p className="mb-0">Los puntos se actualizan cada 24 horas.</p>
                    <p className="terms mt-0">Actividad válida del 11 de marzo al 27 de abril del 2024.</p> */}
                </div>
                {/* <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/portal-bottom-image.svg" alt="" /> */}

        </div>
    </div>
    </div>
    </>
    : showComponentCol.showPan && pais === "JP" ?
    <>
    {isHidden && <PromoComponent code={codePromo} stateIsActive={setIsHidden} campaing={promoId} />}
    <div className={`contenedor-tarjeta`}>
      <div className="container d-flex justify-content-center">
        <div className="row flex-column santipunto-container p-0">
        { !isActive ? <img src="https://cdn.shopify.com/s/files/1/0516/5478/7271/files/PORTAL-CLIENTE-2024-refe.jpg?v=1718217422" alt="" />  : 
                      <img src="https://cdn.shopify.com/s/files/1/0516/5478/7271/files/PORTAL-CLIENTE-2024-b-refe-active.jpg?v=1718217422" alt="" key={"button"} onClick={() => { setIsHidden(true);}}  /> }
        </div>
    </div>
    </div>
    </> 
    :
    null

  )
}
